<template>
<div>
  <div v-for="(val, i) in list" :key="i">
    <div class="box">
      <div class="left_count">
        <div class="count_box">
          <img class="count_img" :src="val.img" alt="">
          <div>
            <p class="count_num"><span>{{val.value}}</span>{{val.unit}}</p>
            <p class="count_tip">{{val.name}}</p>
          </div>
        </div>
      </div>
      <div class="right_list">
        <ul>
          <li v-for="(item, index) in val.list" :key="item.name" @click="setActive(index, i, item.id, item.name)">
            <div class="list_item" :class="activeIndex == index && activeName == i ? 'activeLi' : ''">
              <div class="left_dian"><span :class="'color' + (index % 4)"></span></div>
                <div class="right_text">
                <p>{{item.name}}</p>
                <span>{{item.value}}{{item.unit}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="split_line" v-if=" list.length == 2 && i == 0"></div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data() {
    return {
      activeIndex: null,
      activeName: 0
    }
  },
  methods: {
    setActive (index, i, id, name) {
      if (index === this.activeIndex && i === this.activeName) {
        this.activeIndex = null
        this.$emit('change')
      } else {
        this.activeIndex = index
        this.activeName = i
        this.$emit('change', i + 1, id, name)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
ul, li {list-style: none; margin: 0; padding:  0;}
.split_line {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid #eee;
}
.box {
  display: flex;
  flex-direction: column;
  padding-bottom: 35px;
  .left_count {
    display: flex;
    justify-content: center;
    align-items: center;
    .count_box {
      display: flex;
      justify-content: center;
      .count_img {
        width: 160px;
        height: 160px;
        font-size: 0;
      }
      .count_num {
        width: 100%;
        font-size: 20px;
        margin-top: 40px;
        color: #333;
        text-align: center;
        span {
          display: inline-block;
          color: #4177FF;
          font-size: 36px;
        }
      }
      .count_tip {
        text-align: center;
        font-size: 26px;
      }
    }
  }
  .right_list {
    flex: 1;
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0 14px;
      .activeLi {
        border: 2px solid #387FF5 !important;
        background: rgba(56, 127, 245, .1);
      }
      li {
        display: flex;
        margin: 25px 15px 0;
        .list_item {
          display: flex;
          width: 210px;
          height: 100px;
          border: 2px solid #ccc;
          border-radius: 20px;
        }
        .left_dian {
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          .color0 {background-color: #387FF5;}
          .color1 {background-color: #23D87B;}
          .color2 {background-color: #FFC955;}
          .color3 {background-color: #FF5555;}
        }
        .right_text {
          flex: 1;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          p {
            width: 100%;
            font-size: 26px;
            margin: 0;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          span {
            display: inline-block;
            color: #999;
            font-size: 28px;
          }
        }
      }
    }
  }
}

</style>